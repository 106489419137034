body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}
body::-webkit-scrollbar {
  display: none;
}
::-webkit-scrollbar {
  height: 6px;
  width: 0.5rem;
  background: #f1f1f1;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  box-shadow: inset 0 0 5px rgb(158, 158, 158);
  border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* fonts */
@font-face {
  font-family: "Anuphan";
  src: url("./fonts/Anuphan-Bold.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "Anuphan";
  src: url("./fonts/Anuphan-Thin.ttf") format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: "Anuphan";
  src: url("./fonts/Anuphan-ExtraLight.ttf") format("truetype");
  font-weight: 200;
}
@font-face {
  font-family: "Anuphan";
  src: url("./fonts/Anuphan-Light.ttf") format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: "Anuphan";
  src: url("./fonts/Anuphan-Regular.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Anuphan";
  src: url("./fonts/Anuphan-Medium.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "Anuphan";
  src: url("./fonts/Anuphan-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Varsity Regular";
  src: url("./fonts/varsity_regular.ttf") format("truetype");
  font-weight: 400;
}
